import React from 'react'

//import the sections
import Header from '../components/Head.js'
import Hero from '../components/Hero.js'
import Parts from '../components/Parts.js'
import Sales from '../components/Sales.js'
import Services from '../components/Services.js'
import Contact from '../components/contact.js'

//import the styles
import Layout from '../components/Layout.js'


export default function Home() {


  return (
    <Layout >
      <Header title="Home" />
      <Hero id="home"/>
      <Services id="service" />
      <Sales id="sales" />
      <Parts id="parts" />
      <Contact id="contact" />
    </Layout>
  )
}
