import React from 'react'

import { graphql, useStaticQuery, Link } from 'gatsby'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import containerStyles from './main.module.scss'
import contactStyles from './contact.module.scss'

const Contact = (props) => {
    const data = useStaticQuery(graphql`
        query {
            contentfulContactInfo {
                contactinfo {
                    json
                }
                hours {
                    json
                }
            }
        }
    `)
    return (
        <div className={`${contactStyles.container}`} id={props.id}>
            <div>
                <h1>Contact</h1>
                <div className={contactStyles.contactInfo}>
                    <div className={contactStyles.info}>
                        {documentToReactComponents(data.contentfulContactInfo.contactinfo.json)}
                        <Link className={containerStyles.btn} to="/request">Request Service</Link>
                    </div>
                    <div className={`${contactStyles.info} ${contactStyles.hours}`}>
                        {documentToReactComponents(data.contentfulContactInfo.hours.json)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact