import React from 'react'
import { GoogleMap, LoadScript, Marker} from '@react-google-maps/api'

const mapStyle = {
    minWidth: '300px',
    height: '400px',
    margin: '0 0 0 0',
    padding: '0 0 0 0',
  };
  
  const center = {
    lat: 34.808569,
    lng: -83.420921
  };

  const onLoad = marker => {
      console.log('marker: ', marker)
  }

  const Maps = () => {
      return (
          <LoadScript
          googleMapsApiKey={process.env.GATSBY_GMS_API_KEY}>

            <GoogleMap
            mapContainerStyle={mapStyle}
            center={center}
            zoom={15}
            >

            </GoogleMap>

          </LoadScript>
      )
  }

  export default Maps