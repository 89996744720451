import React from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import containerStyles from './main.module.scss'
import salesStyles from './sales.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

import Maps from './map.js'



const Sales = (props) => {
    const data =  useStaticQuery(graphql`
    query {
        contentfulSales {
          salesHero {
            file {
              url
            }
          }
          salesParagraphs {
            json
          }
          online {
            json
          }

          visitUs {
            json
          }
        }

        whirlpoolLogo: file(relativePath: { eq: "whirlpool.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }

        amanaLogo: file(relativePath: { eq: "amana.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        maytagLogo: file(relativePath: { eq: "maytag.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
  
        kitchLogo: file(relativePath: { eq: "kitchenaid.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        whirlpoolBW: file(relativePath : { eq: "kitchen1.jpg" }) {
          childImageSharp {
            fluid (grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        whirlpoolBG: file(relativePath: { eq: "kitchen1.jpg" }) {
          childImageSharp {
            fluid (duotone: {
              highlight: "#FFFFFF",
              shadow: "#EEB111"},
              toFormat: PNG)
            {
              ...GatsbyImageSharpFluid
            }
          }
        }

        amanaBG: file(relativePath: { eq: "kitchen2.jpg" }) {
          childImageSharp {
            fluid (grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        maytagBG: file(relativePath: { eq: "laundry1.jpg" }) {
          childImageSharp {
            fluid (grayscale: true){
              ...GatsbyImageSharpFluid
            }
          }
        }

        kitchBG: file(relativePath: { eq: "kitchen3.jpg" }) {
          childImageSharp {
            fluid (grayscale: true){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

    //Mulitple Background Arrays
    
    return (
      <div className={containerStyles.div} id={props.id}>
        <div className={salesStyles.hero} style={{ 'backgroundImage': `url(${data.contentfulSales.salesHero.file.url})` }}>
          <h1>Sales</h1>
        </div>
        <div className={`${containerStyles.container}`}>
      
            <div className={`${containerStyles.div} ${salesStyles.content}`}>
                {documentToReactComponents(data.contentfulSales.salesParagraphs.json)}
                <h4>Our Brands</h4>
                <div className={salesStyles.pics}>
                  <img src={data.whirlpoolLogo.childImageSharp.fluid.src} alt=""/>
                  <img src={data.amanaLogo.childImageSharp.fluid.src} alt=""/>
                  <img src={data.maytagLogo.childImageSharp.fluid.src} alt=""/>
                  <img src={data.kitchLogo.childImageSharp.fluid.src} alt=""/>
                </div>
                {documentToReactComponents(data.contentfulSales.visitUs.json)}
                <Maps style={salesStyles.map}/>
                {documentToReactComponents(data.contentfulSales.online.json)}
                
                
            </div>
        </div>
        <div className={salesStyles.links}>
          <a href="https://www.whirlpool.com" target="_blank" rel="noreferrer">
            <BackgroundImage className={salesStyles.bgs} fluid={data.kitchBG.childImageSharp.fluid}>
              <div className={salesStyles.inside}>
                <img src={data.whirlpoolLogo.childImageSharp.fluid.src} alt=""/>
                <span className={salesStyles.btn}>View Website</span>
              </div>
            </BackgroundImage>
          </a>
          <a href="https://www.amana.com" target="_blank" rel="noreferrer">
          <BackgroundImage className={salesStyles.bgs} fluid={data.amanaBG.childImageSharp.fluid}>
              <div className={salesStyles.inside}>
                <img src={data.amanaLogo.childImageSharp.fluid.src} alt=""/>
                <span className={salesStyles.btn}>View Website</span>
              </div>
            </BackgroundImage>
          </a>
          <a href="https://www.maytag.com" target="_blank" rel="noreferrer">
          <BackgroundImage className={salesStyles.bgs} fluid={data.maytagBG.childImageSharp.fluid}>
              <div className={salesStyles.inside}>
                <img src={data.maytagLogo.childImageSharp.fluid.src} alt=""/>
                <span className={salesStyles.btn}>View Website</span>
              </div>
            </BackgroundImage>
          </a>
          <a href="https://www.kitchenaid.com/major-appliances.html" target="_blank" rel="noreferrer">
          <BackgroundImage className={salesStyles.bgs} fluid={data.whirlpoolBW.childImageSharp.fluid}>
              <div className={salesStyles.inside}>
                <img src={data.kitchLogo.childImageSharp.fluid.src} alt=""/>
                <span className={salesStyles.btn}>View Website</span>
              </div>
            </BackgroundImage>
          </a>
        </div>
      </div>
    )
}

const StyledBGs = styled(BackgroundImage)`
  &:before, &.after {
    visibility: hidden;
  }

  &:hover {
    &:before, &:after {
      visibility: visivble;
    }
  }
`

export default Sales