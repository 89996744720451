import React from 'react'

import containerStyles from './main.module.scss'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { graphql, useStaticQuery } from 'gatsby'

import { FontAwesomeIcon } from  '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/pro-light-svg-icons'

const Parts = (props) => {
    const data = useStaticQuery(graphql`
        query {
            contentfulParts {
                partsInfo {
                    json
                }
            }
        }
    `)

    return (
        <div className={`${containerStyles.container} ${containerStyles.grey} ${containerStyles.parts}`} id={props.id}>
             <FontAwesomeIcon icon={faCogs} transform={{ rotate: -45 }}className={containerStyles.icons} />
             <div className={containerStyles.div}>
             {documentToReactComponents(data.contentfulParts.partsInfo.json)}
             </div>
        </div>
    )
}

export default Parts