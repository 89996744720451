import React from 'react' 

import contianerStyles from './main.module.scss'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { Link, graphql, useStaticQuery } from 'gatsby'

import { FontAwesomeIcon } from  '@fortawesome/react-fontawesome'
import { faToolbox } from '@fortawesome/pro-light-svg-icons'


const Services = (props) => {
    const data = useStaticQuery(graphql`
        query {
            contentfulServices {
                servicesInfo {
                    json
                }
                servicearea {
                    json
                }
            }
        }
    `)    

    return (
        <div className={`${contianerStyles.container} ${contianerStyles.grey}`} id={props.id}>
            <FontAwesomeIcon icon={faToolbox} transform={{ rotate: -45 }}className={contianerStyles.icons} />
            <div className={contianerStyles.div}>
                <h1>Service</h1>
                {documentToReactComponents(data.contentfulServices.servicesInfo.json)}
                <Link to="/request" className={contianerStyles.btn}>Request Service</Link>
                {documentToReactComponents(data.contentfulServices.servicearea.json)}
            </div>
            
        </div>
    )
}

export default Services