import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

//importing styles
import heroStyles from './hero.module.scss'

//importing fontAwesome

import { FontAwesomeIcon } from  '@fortawesome/react-fontawesome'
import { faToolbox, faCogs,  faTags} from '@fortawesome/pro-light-svg-icons'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Hero = (props) => {
    
    const data = useStaticQuery(graphql`
        query {
            contentfulHeroimg {
                hero {
                    file {
                        url
                    }
                    description
                }
            }
        }
    `)

    return (
        <div className={heroStyles.container} id={props.id}>
            <img className={heroStyles.heroimg} src={data.contentfulHeroimg.hero.file.url} alt={data.contentfulHeroimg.hero.description}/>
            <h1>Deal's Appliance Service</h1>
            <h3>Appliance Specialists Servicing Northeast Georgia and Parts of Macon County, North Carolina</h3>
            <div className={heroStyles.info}>
                <AnchorLink to="/#service" className={heroStyles.btn}>
                    <FontAwesomeIcon icon={faToolbox} className={heroStyles.icon}/>
                    <h2>Service</h2>
                </AnchorLink>
                <AnchorLink to="/#sales" className={heroStyles.btn}>
                    <FontAwesomeIcon icon={faTags} className={heroStyles.icon}/>
                    <h2>Sales</h2>
                </AnchorLink>
                <AnchorLink to="/#parts" className={heroStyles.btn}>
                    <FontAwesomeIcon icon={faCogs} className={heroStyles.icon}/>
                    <h2>Parts</h2>
                </AnchorLink>               
            </div>
        </div>
    )
}

export default Hero